.floating-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fbb555;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  z-index: 1000;
}

.floating-nav ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.floating-nav li {
  margin: 0 15px;
}

.floating-nav a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  text-decoration: none;
  font-size: 14px;
}

.floating-nav i {
  font-size: 20px;
  margin-bottom: 5px;
}

.floating-nav span {
  text-transform: uppercase;
}
